import React from 'react';
import styled, { css } from 'styled-components';
import { Head, Header, Loading } from '@/components/shared';

const SigninLayout = ({ children, title }) => {
  return (
    <React.Fragment>
      <Head title={title} />
      <Header />
      <Main>{children}</Main>
    </React.Fragment>
  );
};

const Main = styled.main`
  width: 100%;
  min-width: 1250px;
  margin: auto;
  flex: 1;
`;

export default SigninLayout;

import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Loading } from '@/components/shared';
import { Head } from '@/components/shared';
import Router, { useRouter } from 'next/router';
import Link from 'next/link';

import { AtagPrimary } from '@/components/atoms/Atag';

import { SlideDown } from 'react-slidedown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-slidedown/lib/slidedown.css';
import { useAuthContext } from '@/context/AuthContext';
import { useGetCategoriesAll, useGetDirectorsCategoriesAll } from '@/lib/hooks';

// ※必須 user,
// ※必須 title：Headにくるtitle
// ※必須 active：今いるページに該当するものの色を変える

// 768px = 1000px-150px(サイドメニューのwidth)
// height:38px; → ヘッダーの高さ

const DashboardLayout = ({ children, title, active, activeSite, activeSub }) => {
  const { user } = useAuthContext();

  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // カテゴリを取得する
  useEffect(() => {
    const hooks = async () => {
      const hooksData = await useGetCategoriesAll();
      if (hooksData.status === 200) {
        setData(hooksData.data);
      } else {
        setError(true);
        setErrorMessage(hooksData.message);
        setData([]);
      }
    };
    hooks(); // 実行する
  }, []);

  useEffect(() => {
    const hooks = async () => {
      const hooksData = await useGetDirectorsCategoriesAll();
      if (hooksData.status === 200) {
        setData2(hooksData.data);
      } else {
        setError(true);
        setErrorMessage(hooksData.message);
        setData2([]);
      }
    };
    hooks(); // 実行する
  }, []);

  if (!data) return <Loading />;
  if (!data2) return <Loading />;
  if (error) return <h1>{errorMessage}</h1>;

  return (
    <>
      <Head title={title} />
      <DashboardLayoutHeader user={user} />
      <Layout>
        {data.length === 0 ? (
          <h1 css="margin-top:30px;">システムエラーが発生しました。</h1>
        ) : (
          <React.Fragment>
            <SideMenu
              active={active}
              activeSite={activeSite}
              activeSub={activeSub}
              data={data}
              data2={data2}
            />
            <Main>
              <div css="width: 100%;min-width: 768px;">{children}</div>
            </Main>
          </React.Fragment>
        )}
      </Layout>
    </>
  );
};

const Main = styled.div`
  // width: calc(100% - 150px);
  width: 100%;
  padding-top: 80px;
  padding-left: 382px;
`;

const Layout = styled.div`
  width: 100%;
  min-width: 1000px;
  display: flex;
  margin: 0 auto;
`;

export default DashboardLayout;

const SideMenu = React.memo(({ active, activeSite, data, data2, activeSub }) => {
  const router = useRouter();

  const [openMenu1, setOpenMenu1] = useState(activeSite === 'general');
  const [openMenu2, setOpenMenu2] = useState(activeSite === 'members');
  const [openMenu3, setOpenMenu3] = useState(activeSite === 'directors');
  const [openMenu4, setOpenMenu4] = useState(activeSite === 'users');

  return (
    <div css="height: 100%;background: #F4F5F6;width: 150px;color: #000;overflow-y: auto;position: fixed;top: 80px;z-index: 1100;">
      <SidemenuUl>
        <SidemenuLi>
          <SiteTitle
            active={openMenu1}
            onClick={() => {
              router.push('/general');
              setOpenMenu1(true), setOpenMenu2(false), setOpenMenu3(false), setOpenMenu4(false);
            }}
            clickactive={openMenu1}
            css="color: #000;font-weight:bold;&:hover{background:#fff;}"
          >
            市民サイト
          </SiteTitle>
        </SidemenuLi>
        <SidemenuLi>
          <SiteTitle
            active={openMenu2}
            onClick={() => {
              router.push('/members');
              setOpenMenu1(false), setOpenMenu2(true), setOpenMenu3(false), setOpenMenu4(false);
            }}
            clickactive={openMenu2}
            css="color: #000;font-weight:bold;&:hover{background:#fff;}"
          >
            会員サイト
          </SiteTitle>
        </SidemenuLi>
        <SidemenuLi>
          <SiteTitle
            active={openMenu3}
            onClick={() => {
              router.push('/directors');
              setOpenMenu1(false), setOpenMenu2(false), setOpenMenu3(true), setOpenMenu4(false);
            }}
            clickactive={openMenu3}
            css="color: #000;font-weight:bold;&:hover{background:#fff;}"
          >
            理事サイト
          </SiteTitle>
        </SidemenuLi>
        <SidemenuLi>
          <SiteTitle
            active={openMenu4}
            onClick={() => {
              router.push('/users');
              setOpenMenu1(false), setOpenMenu2(false), setOpenMenu3(false), setOpenMenu4(true);
            }}
            clickactive={openMenu4}
            css="color: #000;font-weight:bold;&:hover{background:#fff;}"
          >
            会員管理
          </SiteTitle>
        </SidemenuLi>
      </SidemenuUl>
      {openMenu1 && <Sidemenu active={active} />}
      {openMenu2 && <Sidemenu2 active={active} data={data} activeSub={activeSub} />}
      {openMenu3 && <Sidemenu3 active={active} data={data2} activeSub={activeSub} />}
      {openMenu4 && <Sidemenu4 active={active} />}
    </div>
  );
});

//市民サイトメニュー
const Sidemenu = React.memo(({ active, user }) => {
  return (
    <Article>
      <SidemenuUl>
        <SidemenuLi>
          <Link href="/general" passHref>
            <SubTitle as="a" active={active === 'notices' ? true : false}>
              お知らせ
            </SubTitle>
          </Link>
        </SidemenuLi>
      </SidemenuUl>
    </Article>
  );
});

//会員サイトメニュー
const Sidemenu2 = React.memo(({ active, activeSub, data }) => {
  // {menu0: false, menu1: false ...} をカテゴリの数の分作成
  const menuObj = Object.assign(
    {},
    ...data.map((item, index) => ({
      [`menu${index}`]: item.name === active,
    }))
  );

  const [open, setOpen] = useState(menuObj);

  return (
    <Article css="padding-bottom:110px;">
      <SidemenuUl>
        {data.map((main, mainIndex) => (
          <SidemenuLi className="Sidemenu" key={mainIndex}>
            <SubTitle
              active={active === main.name}
              onClick={() => setOpen({ ...open, [`menu${mainIndex}`]: !open[`menu${mainIndex}`] })}
              clickactive={open[`menu${mainIndex}`]}
            >
              {main.Japanese_name}
              <FontAwesomeIcon
                css="font-size: 17px;float: right;margin-top: 5px;"
                icon={['fas', 'chevron-down']}
              />
            </SubTitle>
            <MyDropdown open={open[`menu${mainIndex}`]}>
              <SidemenuSubUl>
                {main.sub_categories.map((sub, subIndex) => (
                  <SidemenuSubLi key={subIndex} active={activeSub === sub.name}>
                    <SubMemuItem
                      href={`/members/list/${main.name}/${sub.name}`}
                      text={sub.Japanese_name}
                    />
                  </SidemenuSubLi>
                ))}
              </SidemenuSubUl>
            </MyDropdown>
          </SidemenuLi>
        ))}
      </SidemenuUl>
    </Article>
  );
});

//理事サイトメニュー
const Sidemenu3 = React.memo(({ active, activeSub, data }) => {
  // {menu0: false, menu1: false ...} をカテゴリの数の分作成
  const menuObj = Object.assign(
    {},
    ...data.map((item, index) => ({
      [`menu${index}`]: item.name === active,
    }))
  );

  const [open, setOpen] = useState(menuObj);

  return (
    <Article css="padding-bottom:110px;">
      <SidemenuUl>
        {data.map((main, mainIndex) => (
          <SidemenuLi className="Sidemenu" key={mainIndex}>
            <SubTitle
              active={active === main.name}
              onClick={() => setOpen({ ...open, [`menu${mainIndex}`]: !open[`menu${mainIndex}`] })}
              clickactive={open[`menu${mainIndex}`]}
            >
              {main.Japanese_name}
              <FontAwesomeIcon
                css="font-size: 17px;float: right;margin-top: 5px;"
                icon={['fas', 'chevron-down']}
              />
            </SubTitle>
            <MyDropdown open={open[`menu${mainIndex}`]}>
              <SidemenuSubUl>
                {main.sub_categories.map((sub, subIndex) => (
                  <SidemenuSubLi key={subIndex} active={activeSub === sub.name}>
                    <SubMemuItem
                      href={`/directors/list/${main.name}/${sub.name}`}
                      text={sub.Japanese_name}
                    />
                  </SidemenuSubLi>
                ))}
              </SidemenuSubUl>
            </MyDropdown>
          </SidemenuLi>
        ))}
      </SidemenuUl>
    </Article>
  );
});

// 会員管理メニュー
const Sidemenu4 = React.memo(({ active, user }) => {
  return (
    <Article>
      <SidemenuUl>
        <SidemenuLi>
          <Link href="/users" passHref>
            <SubTitle as="a" active={active === 'users' ? true : false}>
              会員一覧
            </SubTitle>
          </Link>
        </SidemenuLi>
      </SidemenuUl>
    </Article>
  );
});

// const Sidemenu3 = React.memo(({ active, user }) => {
//   return (
//     <Article>
//       <SidemenuUl>
//         <SidemenuLi>
//           <Link href="/comingsoon" passHref>
//             <SubTitle as="a" active={active === 'comingsoon' ? true : false}>
//               理事会
//             </SubTitle>
//           </Link>
//         </SidemenuLi>

//         <SidemenuLi>
//           <Link href="/comingsoon" passHref>
//             <SubTitle as="a" active={active === 'comingsoon' ? true : false}>
//               常務会
//             </SubTitle>
//           </Link>
//         </SidemenuLi>
//       </SidemenuUl>
//     </Article>
//   );
// });
const SiteTitle = styled.div`
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 13px 10px 12px;

    &:hover{
	//   background: #fff;
    //   border-left: 5px solid #e8a100;
	opacity:0.7;
	}
	${(props) =>
    props.active &&
    css`
      background: #fff;
      border-left: 5px solid #e8a100;
    `}
	${(props) =>
    props.clickactive &&
    css`
      box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
    `}
}
`;
const SubTitle = styled.div`
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 13px 15px 12px;

    &:hover{
	  background: #3a5ea1;
      border-left: 5px solid #e8a100;
	}
	${(props) =>
    props.active &&
    css`
      background: #3a5ea1;
      border-left: 5px solid #e8a100;
    `}
	${(props) =>
    props.clickactive &&
    css`
      box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
    `}
}
`;

const SidemenuSubUl = styled.ul`
  margin: 5px 0px;
`;

const SidemenuSubLi = styled.li`
  padding: 5px 0px 5px 40px;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 14px;
  &:hover {
    background: #6180be;
  }
  &::before {
    background: #ffffff;
    content: '';
    height: 8px;
    left: 23px;
    margin-top: 12px;
    position: absolute;
    width: 8px;
    border-radius: 50%;
    z-index: 1;
  }
  &::after {
    border-left: 2px solid #475a67;
    bottom: 0;
    content: '';
    left: 26px;
    position: absolute;
    top: 0;
  }
  & a {
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    &:hover {
      background: #6180be;
    }
  }
  ${(props) =>
    props.active &&
    css`
      background: #3a5ea1;
    `}
`;

const Article = styled.article`
  //   height: 100vh;
  height: 100%;
  background: #254687;
  width: 232px;
  color: #ffffff;
  overflow-y: auto;
  position: fixed;
  top: 80px;
  left: 150px;
  z-index: 1100;
`;

const SidemenuUl = styled.ul``;

const SidemenuLi = styled.li`
  list-style: none;
  font-size: 14px;
  &:hover ul {
    display: block;
  }
`;

// passHrefは必須、これがないときちんとリンクが貼れないらしい
//
const SubMemuItem = ({ href, as, text }) => {
  return (
    <>
      <Link href={href} as={as} passHref>
        <a
          css={`
            display: block;
            padding: 9px;
          `}
        >
          {text}
        </a>
      </Link>
    </>
  );
};

export const DashboardLayoutHeader = React.memo(({ user }) => {
  return (
    <HeaderWrapper>
      <img src="/images/logo_admin.svg" css="height:80%;" />
      {user && (
        <div>
          <div css="font-size: 12px;font-weight: bold;">
            <AtagPrimary href="/logout">ログアウト</AtagPrimary>
          </div>
          <div css="font-size: 12px;font-weight: bold;">
            <p>{user.name}</p>
          </div>
        </div>
      )}
    </HeaderWrapper>
  );
});

//スライドダウン
export function MyDropdown(props) {
  return (
    <SlideDown css={``} className={'my-dropdown-slidedown'}>
      {props.open ? props.children : null}
    </SlideDown>
  );
}

// 768px = 1000px-150px(サイドメニューのwidth)
const HeaderWrapper = styled.div`
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 80px;
  width: 100%;
  min-width: 768px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
`;
